<template>
  <v-container fluid class='report-card align-self-sm-stretch'>
    <v-row class='d-flex card-title'>
      <v-row class='pa-0 ma-0 d-flex justify-space-between'>
        <span class='report-title'> {{ $t('TOTAL_AUTENTICACAO_FALHAS_REPORT_TITLE') }} </span>
        <v-icon
          class='mr-4'
          small
          @click='fetchWidgetContent'
        >
          mdi-reload
        </v-icon>
      </v-row>
    </v-row>
    <v-row class='active-filters-text'>
      <span>{{ $t('FROM') }}: </span>
      <span class='ml-1'>{{ `${startDate.substring(8, 10)}/${startDate.substring(5, 7)}/${startDate.substring(0, 4)}` }}</span>
      <span class='ml-4'>{{ $t('UNTIL') }}: </span>
      <span class='ml-1'>{{ `${endDate.substring(8, 10)}/${endDate.substring(5, 7)}/${endDate.substring(0, 4)}` }}</span>
    </v-row>
    <v-divider class='my-6' />
    <v-row class='d-flex justify-center align-stretch font-weight-black content-value primary--text display-3'>
      <span> {{ value }} </span>
    </v-row>
    <v-row
      class='report-footer'
    >
      <v-icon
        @click='openFilters'
      >
        mdi-filter-outline
      </v-icon>
    </v-row>
    <v-dialog
      v-model='filterDialog'
      fullscreen
      hide-overlay
      transition='dialog-bottom-transition'
    >
      <v-card>
        <Filters @onFilter='saveFilter' @applyFilters='fetchWidgetContent' @close='filterDialog = false' />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    components: {
      Filters: () => import('./Filters.vue'),
    },
    data () {
      return {
        reportName: 'Total Autenticações Falhas',
        value: null,
        startDate: null,
        endDate: null,
        filterDialog: false,
      };
    },
    mounted: function () {
      const today = new Date().toISOString().split('T')[0];
      this.startDate = `${today} 00:00:00}`;
      this.endDate = `${today} 23:59:59}`;
      this.fetchWidgetContent();
    },
    methods: {
      async fetchWidgetContent () {
        this.filterDialog = false;
        const { data } = await axios({
          url: '/report-data',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
          params: {
            name: this.reportName,
            filter: {
              date_filter: `created_at >= '${this.startDate}' and created_at <= '${this.endDate}'`,
            },
          },
        });
        this.value = data && data.data && data.data[0] ? data.data[0].total : null;
      },
      removeWidget (id) {
        document.removeItem(id);
      },
      saveFilter (filter) {
        this.startDate = filter.startDate;
        this.endDate = filter.endDate;
      },
      openFilters () {
        this.filterDialog = true;
      },
    },
  };
</script>
<style>
  .report-footer {
    position: absolute;
    bottom: 10vh;
  }
  html {
   overflow: hidden;
  }
  .report-card {
    background-color: #FFFFFF;
    height: 100vh;
    overflow-y: hidden;
  }
  .content-value {
    margin-top: 20vh !important;
  }
  .date-picker {
    z-index: 999;
    animation: createBox 0.5s;
  }
  @keyframes createBox {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  .active-filters-text {
    font-size: 13px;
  }
</style>
